<template>
  <div class="InvoiceToApplyFor">
    <van-tabs v-model="active" class="myOrderTab" title-active-color="#FB884F" color="#FB884F" line-width="0.42rem">
      <van-tab title="可申请">
        <div class="InvoiceToApplyForBackground" v-show="remainToBeEvaluatedData.length==0">
          <img src="../../images/noData.png" />
          <p>
            暂无可申请发票信息，已完结订单才允许申请发票
          </p>
        </div>
        <in-the-lease :listData="remainToBeEvaluatedData"  @check="check"></in-the-lease>
        <div class="InvoiceToApplyForBottom"v-show="remainToBeEvaluatedData.length>0">
          <div>已勾选 {{num}} 个订单</div>
          <div class="next">
            <div>
              合计:<span>￥</span><span>{{ orderMoney.toFixed(2) }}</span>
            </div>
            <div @click="myInvoice">下一步</div>
          </div>
        </div>
      </van-tab>
      <van-tab title="已申请">
        <div class="InvoiceToApplyForBackground" v-show="InTheLeaseData.length==0">
          <img src="../../images/noData.png" />
          <p>
            暂无可申请发票信息，已完结订单才允许申请发票
          </p>
        </div>
        <in-the-lease :listData="InTheLeaseData"></in-the-lease>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import InTheLease from '../../order/components/InTheLease';
import leaseApi from "../../../utils/leaseApi";
import TEMPURL from "../../../utils/tempurl";
import order from "../../order/order";
export default {
  name: "InvoiceToApplyFor",
  components:{
    InTheLease
  },
  data(){
    return{
      active: "",
      backgroundShow:true,
      // 可申请
      remainToBeEvaluatedData:[
      ],
      //已申请
      InTheLeaseData:[
      ],
      num:0,
      orderMoney:0,
      orders:[],
      childrenOrder:[]
    }
  },
  methods:{
    myInvoice(){
      this.$router.push({name: "myInvoice",params:{orders:this.childrenOrder,orderMoney:this.orderMoney}})
    },
    //获取可申请开票的订单
    async getOpenTicketOrderList(){
      const {data} = await leaseApi.getOpenTicketOrderList();
      console.log(data)
      data.forEach((e)=>{
        e.productPic=TEMPURL + e.productPic
        e.deal = 1
      })
      this.remainToBeEvaluatedData=data
    },
    //获取已申请开票的订单
    async getOpenInvoicedList(){
      const {data} = await leaseApi.getOpenInvoicedList();
      data.forEach((e)=>{
        e.productPic=TEMPURL + e.productPic
        e.orderStatus=6
        e.statusStr='交易结束'
      })
      this.InTheLeaseData=data;
    },
   check(v,l,i){
      this.num = v.length
      this.orders=v
      this.orderMoney = l
      let set = new Set(i)
      let arr = Array.from(set);
      this.childrenOrder=arr
    }
  },
  watch:{
  },
  computed:{

  },
  created() {
    this.getOpenTicketOrderList();
    this.getOpenInvoicedList();
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__wrap {
  display: flex !important;
  justify-content: left !important;
  height: 1.14rem !important;
  padding-left: 0.51rem !important;;
  background: #FFFFFF;
  margin-bottom: 0.33rem;
}

/deep/ .van-tab {
  margin-right: 0.86rem !important;
}

/deep/ .van-tab--active {
  font-weight: 400;
}

.InvoiceToApplyFor {
  background: #F6F6F6;
  height: 100%;
}
.InvoiceToApplyForBackground{
  width: 100%;
  height: 100%;
  text-align: center;
  img{
    width: 5.56rem;
    margin-top: 50%;
    transform: translateY(-50%);
  }
  p{
    font-size: 0.36rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #666666;
  }
}
.myOrderTab{
  overflow-y: scroll;
}
.InvoiceToApplyForBottom{
  width: 100%;
  height: 1.64rem;
  background: #FFFFFF;
  display: flex;
  position: fixed;
  bottom: 0px;
  z-index: 9999;
  justify-content: space-between;
  padding-left: 0.44rem;
  >div:nth-child(1){
    font-size: 0.33rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #666666;
    align-self: center;
  }
  .next{
    display: flex;
    align-self: center;
    margin-right: 0.88rem;
    div:nth-child(1){
      font-size: 0.39rem;
      font-family: xiaocheng;
      font-weight: 400;
      color: #333333;
      align-self: center;
      span:nth-child(1){
        font-size: 0.33rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #FB884F;
      }
      span:nth-child(2){
        font-size: 0.44rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #FB884F;
      }
    }
    div:nth-child(2){
      width: 2.69rem;
      height: 1.11rem;
      background: #FB884F;
      box-shadow: 0rem 0rem 0rem 0rem rgba(251, 140, 82, 0.13);
      border-radius: 1rem;
      font-size: 0.39rem;
      font-family: xiaocheng;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.11rem;
      text-align: center;
      margin-left: 0.22rem;
    }
  }
}
/deep/.van-tabs__line{
  width: 0.64rem !important;
  border-top-left-radius: 0.28rem;
  border-top-right-radius: 0.28rem;
}
</style>
