<template>
  <div class="order">
    <van-tabs v-model="active" title-active-color="#FD8E54" line-height="2px" style="height: 100%">
      <van-tab title="全部" class="all" name="all">
        <div class="orderBackground" v-show="listData.length==0">
          <img src="../images/noData.png"/>
          <p>暂无订单数据</p>
        </div>
        <div class="allOrderList">
          <in-the-lease :listData="listData"></in-the-lease>
        </div>
      </van-tab>
      <van-tab title="发货中" name="deliveryOf">
        <div class="orderBackground" v-show="deliveryOfData.length==0">
          <img src="../images/noData.png"/>
          <p>暂无订单数据</p>
        </div>
        <div class="deliveryOf" style="padding: 0rem 0.32rem;">
          <in-the-lease :listData="deliveryOfData"></in-the-lease>
        </div>
      </van-tab>
      <van-tab title="租赁中" name="inTheLease">
        <div class="orderBackground" v-show="inTheLeaseData.length==0">
          <img src="../images/noData.png"/>
          <p>暂无订单数据</p>
        </div>
        <div class="inTheLease" style="padding: 0rem 0.32rem;">
          <in-the-lease :listData="inTheLeaseData"></in-the-lease>
        </div>
      </van-tab>
      <van-tab title="归还中" name="inTheBack">
        <div class="orderBackground" v-show="inTheBackData.length==0">
          <img src="../images/noData.png"/>
          <p>暂无订单数据</p>
        </div>
        <div class="inTheBack" style="padding: 0rem 0.32rem;">
          <in-the-lease :listData="inTheBackData"></in-the-lease>
        </div>
      </van-tab>
      <van-tab title="待评价" style="margin-bottom: 100px" name="remainToBeEvaluated">
        <div class="orderBackground" v-show="remainToBeEvaluatedData.length==0">
          <img src="../images/noData.png"/>
          <p>暂无订单数据</p>
        </div>
        <div class="remainToBeEvaluated" style="padding: 0rem 0.32rem;">
          <in-the-lease :listData="remainToBeEvaluatedData"></in-the-lease>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import InTheLease from './components/InTheLease'
import leaseApi from "../../utils/leaseApi";
import TEMPURL from "../../utils/tempurl";

export default {
  name: "order",
  components: {
    InTheLease
  },
  data() {
    return {
      active: "all",
      allOrderListBackground: false,
      remainToBeEvaluatedShow: false,
      // 全部数据
      listData: [],
      // 发货中数据
      deliveryOfData: [],
      // 租赁中数据
      inTheLeaseData: [],
      // 归还中数据
      inTheBackData: [],
      // 待评价数据
      remainToBeEvaluatedData: []
    };
  },
  methods: {
    show() {
      if (this.listData != []) {
        this.remainToBeEvaluatedShow = false
      } else {
        this.remainToBeEvaluatedShow = true
      }
    },
    // 获取所有订单数据
    async getAllData() {
      const {data} = await leaseApi.getOrderList();
      data.forEach((e) => {
        e.productPic = TEMPURL + e.productPic
        this.listData.push(e)
      })
    },
    // 获取发货中订单数据
    async getExpressDelivery() {
      const {data} = await leaseApi.getExpressDelivery();
      data.forEach((e) => {
        e.productPic = TEMPURL + e.productPic
      })
      this.deliveryOfData = data
    },
    // 获取租赁中订单数据
    async gerLeaseOrder() {
      const {data} = await leaseApi.gerLeaseOrder();
      data.forEach((e) => {
        e.productPic = TEMPURL + e.productPic
      })
      this.inTheLeaseData = data
    },
    // 获取归还中订单数据
    async getReturnOrder() {
      const {data} = await leaseApi.getReturnOrder();
      data.forEach((e) => {
        e.productPic = TEMPURL + e.productPic
      })
      this.inTheBackData = data
    },
    // 获取待评价订单
    async getEvaluatedOrder() {
      const {data} = await leaseApi.getEvaluatedOrder();
      data.forEach((e) => {
        e.productPic = TEMPURL + e.productPic
      })
      this.remainToBeEvaluatedData = data
    },
    activeIng() {
      this.active = this.$route.params.active
    }
  },
  created() {
    this.getAllData()
    this.getExpressDelivery()
    this.gerLeaseOrder()
    this.getReturnOrder()
    this.getEvaluatedOrder()
    this.activeIng()
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__wrap {
  margin-bottom: 0.2rem;
}

/deep/ .van-tabs__nav--line {
  height: 1.14rem !important;
  background: #FFFFFF;
}

.order {
  background: #F6F6F6;
  //height: 100%;
  padding-bottom: 2.2rem;
}

/deep/ .van-tab__pane {
  height: 100vh !important;
}

.orderBackground {
  text-align: center;

  img {
    width: 6.94rem;
    margin-top: 50%;
  }

  p {
    font-size: 0.36rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #666666;
    margin-top: 0.56rem;
  }
}

.allOrderList {
  height: 100%;
  padding: 0rem 0.32rem;
}

/deep/ .van-tabs__line {
  background: #FD8E54;
  width: 0.64rem !important;
  border-top-right-radius: 0.28rem !important;
  border-top-left-radius: 0.28rem !important;
  bottom: 0.38rem;
  height: 0.08rem !important;
}
</style>
